<template>
  <div class="profile-card text-center overflow-hidden">
    <!-- Show the back button -->
    <div class="mb-3 mb-md-6 d-flex width-100">
      <v-btn
        text
        color="primary"
        @click="$router.push({ name: 'InfluencerInsight' })"
      >
        <v-icon left>
          arrow_back
        </v-icon>

        Back
      </v-btn>
    </div>

    <!-- Show the avatar/profile picture -->
    <v-avatar size="160">
      <img :src="proxyUrl(userData.data.user_profile.picture)" />
    </v-avatar>

    <!-- Name and blue-tick for verification -->
    <div class="font-weight-bold text-subtitle-1 mt-3">
      <span>
        {{ userData.data.user_profile.fullname }}
      </span>

      <v-icon v-if="userData.data.user_profile.is_verified" color="blue">verified</v-icon>
    </div>

    <div class="py-2">
      <!-- Profile caption/bio text -->
      <div class="text-body-2">
        {{ userData.data.user_profile.description }}
      </div>
    </div>

    <!-- Show the number of followers and redirect it to their web link on click -->
    <div class="platform-cta text-left my-2 mx-auto">
      <v-list-item ripple @click="redirectToWeb(userData.data.user_profile.url)">
        <v-list-item-action class="mr-3">
          <v-img height="32" width="32" :src="platformIconMap[userData.platform]" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <div class="font-weight-medium platform-name text-capitalize">
              {{ userData.platform }}
            </div>
            <div class="text-gray-custom text-caption">{{ nFormatter(userData.data.user_profile.followers) }}</div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <span class="text-caption" v-bind="attrs" v-on="on">
          Report created: <br /> {{ formatRelativeDate(createdAt) }}
        </span>
      </template>
      <span>
        {{ formatDate(createdAt) }}
      </span>
    </v-tooltip>

    <!-- Show a grid -->
    <div class="mx-auto mt-4" style="max-width: 360px;">
      <v-row no-gutters>
        <!-- First column should be reserved for contact methods -->
        <v-col
          v-if="contactMethods"
          :cols="isUpdateAvailable ? 6 : 12"
          md="12"
          class="px-2 px-md-0 pb-2"
        >
          <v-btn
            @click="shouldShowContactDialog = true"
            color="primary"
            depressed
            outlined
            block
          >
            <v-icon left>
              connect_without_contact
            </v-icon>

            {{ contactMethods.length }} contacts
          </v-btn>
        </v-col>

        <!-- Second column should be reserved for update button -->
        <v-col
          v-if="isUpdateAvailable"
          :cols="contactMethods ? 6 : 12"
          md="12"
          class="px-2 px-md-0 pb-2"
        >
          <v-btn
            color="primary"
            block
            depressed
            :loading="isUpdating"
            :disabled="isUpdating || isViewingOldReport"
            @click="shouldShowUpdateDialog = true"
            max-width="100%"
          >
            <v-icon left> update </v-icon>

            Update
          </v-btn>

          <div v-if="isUpdating" class="progress-loader">
            <v-progress-linear indeterminate />
          </div>
        </v-col>

        <!-- 3rd column should be for PDF Download -->
        <v-col
          cols="6"
          md="12"
          class="px-2 px-md-0 pb-2"
        >
          <v-btn
            color="primary"
            block
            depressed
            :loading="isDownloading"
            :disabled="isLoading"
            @click="triggerDownload('pdf')"
            max-width="100%"
          >
            <v-icon left> picture_as_pdf </v-icon>

            PDF Report
          </v-btn>

          <div v-if="isDownloading" class="progress-loader">
            <v-progress-linear :value="downloadProgress" />
          </div>
        </v-col>

        <!-- 4th column should be for XLSX Download -->
        <v-col
          cols="6"
          md="12"
          class="px-2 px-md-0 pb-2"
        >
          <v-btn
            color="primary"
            block
            depressed
            :disabled="isLoading"
            @click="triggerDownload('xlsx')"
            max-width="100%"
          >
            <v-icon left> description </v-icon>
            XLSX Report
          </v-btn>
        </v-col>

        <!-- If possible show the option to add to group -->
        <v-col
          v-if="isInfluencerGroupsAllowed"
          cols="6"
          md="12"
          class="px-2 px-md-0 pb-md-2"
        >
          <v-btn
            block
            depressed
            color="primary"
            @click="shouldShowInfluencerGroupsForm = true"
          >
            <v-icon left> add </v-icon>

            Group
          </v-btn>
        </v-col>

        <!-- If possible show the option to add to campaign -->
        <v-col
          v-if="isCampaignTrackingAllowed"
          cols="6"
          md="12"
          class="px-2 px-md-0"
        >
          <v-btn
            block
            depressed
            color="primary"
            @click="shouldShowCampaignTrackingForm = true"
          >
            <v-icon left> add </v-icon>

            Campaign
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- Dialog for contact methods -->
    <v-dialog v-model="shouldShowContactDialog" width="500">
      <v-card>
        <v-card-title> Contacts </v-card-title>

        <v-card-text v-if="contactMethods">
          <v-list dense>
            <v-list-item
              v-for="(item, index) in contactMethods"
              :key="index"
              ripple
              two-line
              :href="item.formatted_value"
              class="align-center"
              @click.prevent="redirectToWeb(item.formatted_value)"
            >
              <v-list-item-icon class="mr-4">
                <v-icon class="mt-3" :color="isThemeLight ? 'black' : null">
                  {{ item.type === "email" ? "email" : "public" }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.value }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.formatted_value }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="shouldShowContactDialog = false"> Okay </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirmation dialog for report update -->
    <v-dialog v-model="shouldShowUpdateDialog" width="500">
      <v-card>
        <v-card-title> Update Report? </v-card-title>

        <v-card-text> This will cost you 1 token </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text class="mr-3" color="primary" @click="shouldShowUpdateDialog = false"> Cancel </v-btn>

          <v-btn text color="primary" @click="triggerUpdate"> Update </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <influencer-groups-search
      v-model="shouldShowInfluencerGroupsForm"
      :influencers="computedInfluencers"
    />

    <campaign-tracking-search
      v-model="shouldShowCampaignTrackingForm"
      :influencers="computedInfluencers"
    />
  </div>
</template>

<script>
// Import helper functions
import messageEvents from "@/helpers/messageEvents"
import { getClientID } from "@/helpers/clientHelper"

// Import children components
const InfluencerGroupsSearch = () => import(/* webpackChunkName: "influencer-groups-search" */ "@/components/crm/influencer-groups/Search.vue")
const CampaignTrackingSearch = () => import(/* webpackChunkName: "campaign-tracking-search" */ "@/components/crm/campaign-tracking/Search.vue")

// Define the key for handling subscriptions
const subscriptionId = Symbol("UserProfile/ProfileCard")

// Export the SFC
export default {
  // Name of the component
  name: "ProfileCard",

  // Register children components
  components: {
    InfluencerGroupsSearch,
    CampaignTrackingSearch
  },

  // Accept incoming data from parent
  props: {
    username: String,
    platform: String,
    userData: Object,
    createdAt: Number,
    generatedAt: Number,

    accountId: {
      type: [String, Number],
      required: false,
      default: null
    },

    reportId: {
      type: [String, Number],
      required: false,
      default: null
    },

    isViewingOldReport: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  // Define local data variables
  data: () => ({
    // The checkbox input
    shouldSendEmail: false,
    // The contact options CTA
    shouldShowContactDialog: false,
    // The dialog to confirm whether or not to update the report
    shouldShowUpdateDialog: false,
    // Current profile's download progress
    downloadProgress: 0,
    // The progress interval handler
    progressHandler: null,
    // The timeout interval handler
    timeoutHandler: null,
    // The triggerId to compare with to hide the progress
    triggerId: null,
    // Whether the update request is in queue
    isUpdating: false,
    // Whether the download request is in queue
    isLoading: false,

    // Whether or not to show the dialogs
    shouldShowInfluencerGroupsForm: false,
    shouldShowCampaignTrackingForm: false,
  }),

  // Local readonly computable variables
  computed: {
    /**
     * Whether or not to show the indefinite progress bar
     *
     * @returns {Boolean}
     */
    isDownloading() {
      return this.downloadProgress > 0 && this.downloadProgress < 100
    },

    /**
     * Get the contact options for this profile
     *
     * @returns {Array}
     */
    contactMethods() {
      return this.userData.data.user_profile.contacts
    },

    /**
     * Whether or not the update is available
     *
     * @returns {Boolean}
     */
    isUpdateAvailable() {
      // If the report was created more than 30 days ago
      if (dayjs().diff(this.createdAt, 'day') >= 30) {
        return true
      }

      // Check if stat history is available
      const statHistory = this.userData?.data?.user_profile?.stat_history
      if (statHistory && statHistory.length > 0) {
        // Get the last value
        const lastValue = statHistory[statHistory.length - 1]
        // Parse the date
        const lastDate = dayjs(`${lastValue.month}-05`) // Using day 5th of the month instead of 1st because of data inconsistency
        // Check if the difference is more than 30 days
        if (dayjs().diff(lastDate, 'day') >= 30) {
          return true
        }
      }
      // Otherwise
      else {
        // If the insight was generated more than 30 days ago
        if (dayjs().diff(this.generatedAt, 'day') >= 30) {
          return true
        }

        // If the insight was generated after the report was created
        if (dayjs(this.generatedAt).isAfter(dayjs(this.createdAt))) {
          return true
        }
      }

      // Fallback to
      return false
    },

    /**
     * Whether or not to show the influencer groups form
     *
     * @return {Boolean}
     */
     isInfluencerGroupsAllowed() {
      return this.$store.getters['auth/isServiceAllowed'](constants.model.user.allowedServices.influencerGroups)
    },

    /**
     * Whether or not to show the campaign tracking form
     *
     * @return {Boolean}
     */
    isCampaignTrackingAllowed() {
      return this.$store.getters['auth/isServiceAllowed'](constants.model.user.allowedServices.campaignTracking)
    },

    /**
     * Get the computed influencers
     *
     * @returns {Array}
     */
    computedInfluencers() {
      return [
        {
          id: this.reportId || this.accountId || this.userData.data.user_profile.user_id,
          platform: this.platform,
          username: this.userData.data.user_profile.username,
          fullname: this.userData.data.user_profile.fullname,
          account_id: this.userData.data.user_profile.user_id,
          picture: this.userData.data.user_profile.picture,
          is_verified: this.userData.data.user_profile.is_verified,
          url: this.userData.data.user_profile.url,
        }
      ]
    }
  },

  // Define method functions
  methods: {
    /**
     * Update the value for progress bar
     *
     * @returns {void}
     */
    moveProgress() {
      if (this.downloadProgress >= 90) {
        window.clearInterval(this.progressHandler)
      } else {
        this.downloadProgress += 6
      }
    },

    /**
     * Trigger download network request
     *
     * @param {String} type
     * @returns {void}
     */
    triggerDownload(type) {
      // Disable the button
      this.isLoading = true
      this.triggerId = String(Date.now())

      // Trigger the Vuex action to make network request
      this.$store.dispatch("influencerInsight/downloadReport", {
        platform: this.platform,
        username: this.username,
        accountId: this.accountId,
        triggerId: this.triggerId,
        reportId: this.reportId,
        shouldSendEmail: this.shouldSendEmail,
        type
      })

      // Add a timeout handler
      this.timeoutHandler = window.setTimeout(() => {
        // If didn't start it after 10 seconds, show an error
        this.$store.dispatch("toasts/add", { text: "Request timed out, please try again!" })
      }, 10_000)
    },

    /**
     * Stop the download progress
     *
     * @param {Event} event
     * @returns {void}
     */
    handleMessageEvent(event) {
      // If the clientId does not match
      if (getClientID() !== event.localData.clientId || this.triggerId !== event.localData.triggerId) {
        // Stop further execution
        return
      }

      // Switch through the cases
      switch (event.key) {
        case "generate-influencer-insight-pdf-started":
          this.downloadProgress = 1

          // Every 1 second, move the progress bar a bit more
          this.progressHandler = window.setInterval(this.moveProgress, 600)

          // Remove the timeout handler
          window.clearTimeout(this.timeoutHandler)

          break

        case "generate-influencer-insight-pdf-completed":
          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Downloading your report..." })

          // Hide progress
          window.clearInterval(this.progressHandler)
          this.downloadProgress = 0
          this.isLoading = false

          break

        case "generate-influencer-insight-pdf-failed":
          // Show a message saying it failed
          this.$store.dispatch("toasts/add", { text: "Could not generate your report, please contact us." })

          // Hide progress
          this.downloadProgress = 0
          this.isLoading = false
          window.clearInterval(this.progressHandler)

          break

        case "generate-influencer-insight-xlsx-completed":
          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Downloading your report..." })

          // Hide progress
          this.isLoading = false
          window.clearInterval(this.progressHandler)

          break

        case "generate-influencer-insight-xlsx-failed":
          // Show a message saying it failed
          this.$store.dispatch("toasts/add", { text: "Could not generate your report, please contact us." })

          // Hide progress
          this.isLoading = false
          window.clearInterval(this.progressHandler)

          break
      }
    },

    /**
     * Trigger the network request to fetch the new report for this user
     *
     * @returns {void}
     */
    triggerUpdate() {
      // Hide the dialog
      this.shouldShowUpdateDialog = false

      // Show a loader
      this.isUpdating = true

      // Emit a custom event
      window.dispatchEvent(
        new CustomEvent("InsightReportUpdate", {
          detail: {
            platform: this.platform,
            accountId: this.accountId,
            getRecentReport: true
          }
        })
      )
    }
  },

  /**
   * As soon as the component's data is ready
   *
   * @returns {void}
   */
  created() {
    // Register a subscriber for messageEvents
    messageEvents.register({
      id: subscriptionId,
      module: "influencer-insight-report",
      type: "all",
      key: "all",
      validator: (event) => event.module === "influencer-insight-report",
      callback: this.handleMessageEvent
    })
  },

  /**
   * Before this component is about to be removed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // De-register the event listener
    messageEvents.deregister(subscriptionId)
  }
}
</script>

<style lang="stylus" scoped>
.progress-loader
  width 100%
  border-radius 0 0 16px 16px
  overflow hidden
  margin-top -1px

.platform-cta
  max-width 20em

  .v-list-item
    border 1px solid rgba(145, 158, 171, 0.32)
    border-radius 0.5em
    overflow hidden
</style>
